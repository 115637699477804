<div class="font14 d-flex flex-column justify-content-center my-2 mt-4"
     [ngClass]="currentRoute==='dashboard'&&!currentRoute2? 'purple':'footer-font'">
  <div class="mx-auto d-flex flex-nowrap fw-semibold">
    <div class="my-auto text-black"> payment solutions by |</div>
    <img class="ms-2 mt-1" height="13" src="../../../assets/footer/logo.png" width="77"/></div>
  <div class="mx-auto text-center" style="opacity: 50%!important;">
    © 2024 FlowPay s.r.l - IT06968160488 – Via Francesco Crispi 6, Firenze (FI) - 50129 – Istituto di pagamento, cod.
    Banca d'Italia Ref. 36925
  </div>
  <div class="d-flex mx-auto flex-wrap">
    <a class="me-2 purple" href="https://flowpay.it/privacy-policy" target="_blank">Privacy Policy</a> - <a
    class="ms-2 purple" href="https://flowpay.s3.eu-west-3.amazonaws.com/068DE696-B47D-4B55-A258-9EC6F41C8E33.pdf"
    target="_blank">Termini e Condizioni</a>
  </div>
</div>
